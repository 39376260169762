import React from 'react'
import Layout from '../components/Layout'
import * as styles from '../styles/impressum.module.css'

export default function Impressum() {
    return (
        <Layout>
            <div className={styles.impressum}>
                <h2>Impressum</h2>
                <div className={styles.kontakt}>
                    <h3>Kontakt</h3>
                    <p>Zahnärztin Veneta Nenkova-Bogdanova</p>
                    <p>Louis-Lewin-Str. 8</p>
                    <p>12627 Berlin</p>
                </div>

                <div className={styles.kontaktInf}>
                    <p>Telefon: 0049309917090</p>
                    <p>email: zahnarztpraxis@helledent</p>
                </div>

                <div className={styles.nummer}>
                    <h3>Zulassungsnummer </h3>
                    <p>6478</p>
                </div>

                <div className={styles.zulass}>
                    <h3>Zulassungsbehörde</h3>
                    <p>Kassenzahnärztliche Vereinigung Berlin</p>
                    <p>Georg-Wilhem-Str.16</p>
                    <p>10711 Berlin</p>
                </div>

                <div className={styles.aufsicht}>
                    <h3>Aufsichtsbehörde</h3>
                    <p>Zahnärztekammer Berlin</p>
                    <p>Stallstraße 1</p>
                    <p>10585 Berlin</p>
                    <a href="https://www.zaek-berlin.de/">www.zaek-berlin.de</a>
                    <p>info@zaek-berlin.de</p>
                </div>

                <div className={styles.plus}>
                    <p>+ Zahnheilkundegesetz (ZHG)</p>
                    <p>+ Heilberufe- Kammergesetz</p>
                    <p>+ Gebürenordnung für Zahnärzte (GOZ)</p>
                    <p>+ Berufsordnung für Zahnärzte</p>
                    <p>Einsehbar unter <a href="https://www.kzv-berlin.de/">www.kzv-berlin.de</a></p>
                </div>

                <div className={styles.beruf}>
                    <h3>Berufsbezeichnung</h3>
                    <p>Zahnärztin</p>
                </div>

                <div className={styles.veneta}>
                    <h3>Inhaltlich Verantwoltlicher gemäß §55 Absatz2 RStV</h3>
                    <p>Veneta Nenkova-Bogdanova</p>
                </div>

                <div className={styles.fotos}>
                    <h3>Fotos</h3>
                    <p>Bildnachweis: Shutterstock.com</p>
                </div>

                <div className={styles.itcreate}>
                    <h3>Programierung und Realisierung </h3>
                    <p>ITcreate</p>
                </div>

            </div>
        </Layout>
    )
}
