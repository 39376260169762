// extracted by mini-css-extract-plugin
export var impressum = "impressum-module--impressum--3co7t";
export var kontakt = "impressum-module--kontakt--AAYle";
export var kontaktInf = "impressum-module--kontaktInf--kywvD";
export var nummer = "impressum-module--nummer--1p5eF";
export var zulass = "impressum-module--zulass--3-rsu";
export var aufsicht = "impressum-module--aufsicht--3NSX0";
export var plus = "impressum-module--plus--VkLQQ";
export var beruf = "impressum-module--beruf--zm9Dm";
export var veneta = "impressum-module--veneta--1THAd";
export var fotos = "impressum-module--fotos--ftCGg";
export var itcreate = "impressum-module--itcreate--2EX7D";